import * as React from "react";
import siteSettings from "../constants/siteSettings";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "gatsby";

const WorkDone: React.FC = () => {
  return (
    <section id="workDone" className="workDone">
      <div className="container">
        <div className="row">
          {siteSettings.workDone.map((item, index) => (
            <div className="col-md-3 mt-5">
              <div className="row">
                <div
                  dangerouslySetInnerHTML={{ __html: item.icon }}
                  className="col-4 d-flex justify-content-center align-items-center"
                ></div>
                <div className="col-8">
                  <span className="workDoneNumber">{item.number}+</span>
                  <span className="workDoneTitle">{item.title}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkDone;
