import * as React from "react";
import About from "../components/About";
import BreadCrumbs from "../components/BreadCrumbs";
import DevelopmentProcess from "../components/DevProcess";
import Layout from "../components/layout";
import LetsBuild from "../components/LetsBuild";
import Values from "../components/Values";
import WorkDone from "../components/WorkDone";

// markup
const Index: React.FC = () => {
  return (
    <Layout pageTitle="About Us - Light Infotech Software Solutions">
      <main id="main">
        <BreadCrumbs visitPageTitle={"About Us"} />
        <About />
        <WorkDone />
        <DevelopmentProcess />
        <Values />
        <LetsBuild />
      </main>
    </Layout>
  );
};

export default Index;
